.payment, .selectedPayment, .changePayment {
    display: flex;
    align-items: center;
}

.changePayment {
    justify-content: flex-end;

}

.payment {
    margin-bottom: 50px;
    max-width: 457px;
    width: 100%;
    justify-content: space-between;
}

.container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: fit-content;
    padding: 0 0 70px 0;
}
