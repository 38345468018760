.adsBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

}
.container{
    max-width: 450px;
    align-self: center;
}
.policyBlock{
    height: 37px;
    line-height: 11px;
}

@media (max-width: 767px) {
    .adsBlock{
        margin-bottom: 6px;
    }
    .container{
        max-width: 767px;
    }
}

