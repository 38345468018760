.main {
    background: rgba(255,255,255,.95);
    background: linear-gradient(0deg, rgba(255,255,255,0.90) 0%, rgba(255,255,255,1) 100%);
    backdrop-filter: blur(20px);
    padding-bottom: 5px;
    position: sticky;
    z-index: 10;
    transition-duration: .3s;
    top: 0;
    -webkit-box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0);
    box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0);
}

.mainShrink {
    top: -95px;
    -webkit-box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 5px 4px -2px rgba(0, 0, 0, 0.07);
}

.container {
    max-width: 1200px;
    padding: 20px 0 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    opacity: 1;
    transition-duration: .5s;
    transition-delay: .3s;
}

.infoHidden {
    transition-delay: 0s;
    opacity: 0;
}

.searchPopupAnchor {
    position: relative;
}

.logo {
    display: flex;
    justify-content: space-between;
}

.logoMobile {
    display: none;
}

.seasonMarket {
    display: flex;
    flex-direction: column;

}

.market {
    display: flex;
    padding-left: 1px;
    justify-content: space-between;

}

.logoIcon {
    margin-right: 13px;

}

.middleBlock {
    display: flex;
    flex-direction: column;

}

.delivery {
    background-color: #1850DF;
    border-radius: 4px;
    padding: 7px 25px;
    color: #fff;
    font-size: 16px;
    font-family: 'HelveticaNeueCyr-Roman', sans-serif;
    font-weight: 300;
    width: 100%;
    max-width: 435px;
    text-align: center;
    margin: 0 auto;
}

@media (max-width: 500px) {
    .delivery {
        padding: 7px 0;
    }
}

.date {
    font-weight: 700;
    display: inline-block;
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    border-bottom: solid 1px #fff;
}

.delivery:hover .date {
    border-bottom-color: #00A469;
}

.delivery:hover span {
    color: #00A469;
}

.discount {
    margin-bottom: 10px;

}

.contacts {
    display: flex;
    flex-direction: column;
    position: relative;
}

.helpBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 300px;
    height: 45px;
    background: #00A469;
    border-radius: 40px;
    cursor: pointer;
    z-index: 102;
}

.popUp {
    width: 300px;
    height: 235px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    gap: 23px;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 101;
    border-radius: 24px;
    background: #fff;
    -webkit-box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
    box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
}

.mailBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #1850df;
    border-radius: 40px;
    cursor: pointer;
}

.waBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #65da4b;
    border-radius: 40px;
    cursor: pointer;
}

.input {
    background-color: rgba(206, 206, 206, 0.38);
    height: 50px;
    padding-left: 13px;
    border-radius: 8px;
    border: none;
    outline: none;
    width: 100%;
    max-width: 375px;
    background-image: url("../assets/images/search.svg");
    background-repeat: no-repeat;
    background-position: right 5% bottom 50%;
    flex: 1;

}

.bubble {
    position: relative;
    width: 1px;
    height: 1px;
}

.bubbleInner {
    position: absolute;
    width: 223px;
    max-width: 223px;
    z-index: 100000000000000000;
    top: -22px;
    left: 0;
}

.found {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    transition-duration: .3s;
    align-self: flex-end;
}

.foundShrink {
    width: calc(100% - 76px);
}


.firstBlock {
    display: flex;
    position: relative;
    transition: left .3s;

    left: 0;
    /*gap: 25px;*/
    align-items: center;
    flex: 2;
}


.firstBlock > * {
    margin-right: 25px;
}

.firstBlock > *:last-child {
    margin-right: 0;
}

.firstBlockShrink {
    /*left: 76px*/
}

.logoSmall {
    opacity: 0;
    transition-duration: .3s;
    position: absolute;
    left: -76px;
    transition-delay: 0s;
    margin-left: -20px;
    cursor: pointer;

}

.logoSmallOpaque {
    transition-delay: .3s;
    opacity: 1;
    margin-left: 0;
}

.secondBlock {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    z-index: 100;

}

.firstBlockInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.region {
    color: #BDBDBD;
    margin-right: 14px;
    border-bottom: 1px solid #BDBDBD;
}

.region:hover {
    color: #00A469 !important;
    border-bottom-color: #00A469;
}

.city {
    cursor: pointer;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.category {
    display: flex;
    justify-content: flex-start;
   /*// overflow: aut*/
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: -11px;
}

.categoryItem {
    border-bottom: 4px solid #00A469;
    border-bottom-color: transparent;
    padding-bottom: 5px;
    max-width: 350px;
    width: fit-content;
    height: fit-content;
    margin:11px;
}

.linkActive > .categoryItem {
    border-bottom-color: #00A469;
}

.basketCircle {
    height: 53px;
    width: 53px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(90, 91, 95, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
}

.budge {
    position: absolute;
    top: -3px;
    right: -7px;
    background: #FF5252;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 550;
}

/*Burger*/
.menuBtn {
    align-items: center; /* центрируем содержимое кнопки */
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.menuBtn > div,
.menuBtn > div::before,
.menuBtn > div::after {
    display: block;
    width: 23px;
    height: 3px;
    background-color: #213140;
    border-radius: 4px;
}

.menuBtn > div {
    position: relative;
}

.menuBtn > div::before {
    content: '';
    position: absolute;

    top: -7px;
}

.menuBtn > div::after {
    content: '';
    position: absolute;

    top: 7px;
}


/*Mobile*/
.wrapper {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,1) 100%);
    z-index: 100;
    position: sticky;
    top: -1px
}

.header {
    padding: 18px 20px;

}

.mobileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileDelivery {
    background-color: #1850DF;
    margin-bottom: 30px;
    color: #fff;
    padding: 5px 20px;
    width: 100%;
    min-width: 320px;
    text-align: center;
}

.mobileDiscount {
    background: #00A469;
    text-align: center;
    padding: 6px 20px;
    width: 100%;
    min-width: 320px;
}

.mobileCity {
    margin-top: -32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.popupWrapper {
    position: relative;
    max-width: 383px;
    width: 100%;
}

.popup {
    position: absolute;
    width: 100%;
    top: 52px;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
}

.popupItem {
    padding: 4px 13px;
    color: #213140;
    cursor: pointer;

}

.popupItem:hover, .popupItem:focus {
    background: #ededed;
}

/* 1200px, 992px, 768px, 576px */

@media (max-width: 1200px) {
    .container {
        padding: 20px 15px 0;
    }
}

@media (max-width: 992px) {

    .logoMobile {
        display: inline-block;
    }

    .budge {
        top: -5px;
        right: -8.5px;
    }

    .basketCircle {
        height: 42px;
        width: 42px;
    }

    .basketCircle img {
        height: 25px;
        width: 25px;
    }

}

@media (max-width: 768px) {
    .info {

    }
}

.bubbleImg {
    opacity: 1;
    transition-duration: 1s;
}

.fadeOut {
    opacity: 0;
}
