.wrapper {
    padding: 23px 32px 50px 50px;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.product {
    display: flex;
    /*gap: 30px;*/
    /*max-width: ;*/
}


.product > * {
    margin-right: 30px;
}

.product > *:last-child {
    margin-right: 0;
}

.product img {
    max-width: 151px;
    max-height: 151px;
    width: 100%;
    height: 100vh;
    border-radius: 6px;
}

.info {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    max-width: 460px;
    width: 100%;
}

.mail {
    display: flex;
    /*gap: 20px;*/
}


.mail > * {
    margin-right: 20px;
}

.mail > *:last-child {
    margin-right: 0;
}



.modalTick {
    padding: 25px 60px 35px;
}

@media (max-width: 790px) {
    .wrapper {
        padding: 17px 14px 40px 16px;
        max-width: 303px;
    }

    .header {
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .product {
        /*gap: 12px;*/
    }


    .product > * {
        margin-right: 12px;
    }

    .product > *:last-child {
        margin-right: 0;
    }

    .product img {
        max-width: 103px;
        max-height: 103px;
    }

    .mail {
        flex-direction: column;
        /*gap: 30px;*/
    }


    .mail > * {
        margin-bottom: 30px;
    }

    .mail > *:last-child {
        margin-bottom: 0;
    }
}
