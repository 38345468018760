.wrapper {
    max-width: 1240px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 120px;
}

.container {
    max-width: 891px;
}

.dot {
    padding-left: 24px;
    position: relative;
}

.dot:before {
    content: '';
    display: inline-block;
    background: #213140;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 7px;
}

.instRef:visited {
    text-decoration: underline;
}