.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    align-items: center;
    margin: 110px auto 100px;

}

.image {
    margin-bottom: 60px;
    width: 100%;
    max-width: 480px;

}

.text {
    text-align: center;
    margin-bottom: 45px;

}

.button {
    max-width: 306px;
    height: 50px;
    width: 100%;

}

@media (max-width: 680px) {
    .wrapper {
        max-width: 680px;
        margin: 55px auto 60px;
        padding: 0 32px;

    }

    .image {
        margin-bottom: 40px;
        max-width: 311px;
        text-align: center;

    }

    .text {
        text-align: center;
        margin-bottom: 45px;

    }

    .button {
        max-width: 220px;
        height: 50px;
        width: 100%;

    }

}