.slick-slide {
    visibility: hidden;
}

.slick-slide.slick-active {
    visibility: visible;
}

.slick-dots {
    position: absolute;
    bottom:0;
    left:0;
}

.slick-dots li button:before {
    color: #fff;
    opacity: 0.4;
    font-size: 7px;
}

.slick-active li button:before {
    color: #213140;
    opacity: 0.4;
    font-size: 7px;
}

