.position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-right: 5px;
}

.posInfo {
    display: flex;
    padding: 3px 0 5px;
}

.posIcon > img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    margin-right: 17px;
}

.posName {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0 5px;
}

.productName {
    width: 353px;
}

.amountSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 153px;
    height: 42px;
}

.minus, .plus {
    background: #EDEDED;
    border-radius: 50%;
    width: 38px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.minus > img, .plus > img {
    width: 16px;
}

.price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -15px;
    width: 150px;
}

.sale {
    position: relative;
    margin-bottom: 8px;
}

.sale:before {
    content: '';
    border-bottom: 1px solid #FF5252;
    position: absolute;
    top: 2px;
    border-radius: 1px;
    width: 110%;
    height: 50%;
    left: -2px;
    z-index: 1;
}

.clearBasket {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.clearIcon {
    background: #909090;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.line {
    height: 1px;
    width: 100%;
    max-width: 892px;
    background: #EDEDED;
    border-radius: 2px;
    margin-bottom: 20px;
}

.payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

.promo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.footerPrice {
    display: flex;
    align-items: center;
}

.ordering {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderingButton {
    width: 302px;
    height: 50px;
}

.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    padding-top: 20px;
    padding-bottom: 50px;
}

.promoInput {
    width: 100%;
    margin-bottom: 50px;
}

.promoButton {
    width: 218px;
    height: 50px;
    display: flex;
    justify-content: center;
}

.clearModal {
    background: #909090;
    border-radius: 50%;
    height: 43px;
    width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
}

.clearModal > img {
    width: 20px;
    height: 20px;
}

.delivery {
    background: #213140;
    border-radius: 4px;
    width: 384px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalHeaderBasket {
    height: 122px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.87) 83.85%, rgba(255, 255, 255, 0) 100%);
    position: sticky;
    padding: 45px 49px;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: 2;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45px;
    right: 45px;
    position: absolute;
}

.emptyCart {
    background: url("../assets/images/emptyCart.svg") 0 -110px no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 36px 0 60px;
    height: 528px;
}

@media (max-width: 992px) {
    .position {
        margin-bottom: 30px;
        padding: 0 20px;

    }

    .posInfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    .posIcon > img {
        width: 52px;
        height: 52px;
        border-radius: 6px;
        margin-right: 11px;
    }

    .posName {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 0;
        margin-bottom: 6px;
    }

    .productName {
        width: 164px;
    }

    .amountSelector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 99px;
        height: 26px;
    }

    .minus, .plus {
        background: #EDEDED;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .minus > img, .plus > img {
        width: 11px;
    }

    .price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        width: 100%;
    }

    .sale {
        position: relative;
        margin-bottom: 0;
        margin-right: 15px;
    }

    .sale:before {
        content: '';
        border-bottom: 1px solid #FF5252;
        position: absolute;
        top: 2px;
        border-radius: 1px;
        width: 110%;
        height: 50%;
        left: -2px;
        z-index: 1;
    }

    .clearBasket {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding: 0 20px;
        cursor: pointer;
    }

    .clearIcon {
        background: #909090;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .line {
        height: 1px;
        width: calc(100% - 40px);
        background: #EDEDED;
        border-radius: 2px;
        margin: 0 20px 11px;
        padding: 0 20px;
    }

    .promo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: auto;
        margin-bottom: 18px;
        padding: 0 5px;
    }

    .footerPrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        margin-bottom: 20px;
    }

    .orderingButton {
        width: 306px;
        height: 50px;
    }

    .footer {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: #fff;
        padding: 15px;
    }

    .promoInput {
        width: 100%;
        max-width: 227px;
        margin-bottom: 72px;
    }

    .promoButton {
        width: 218px;
        height: 50px;
        display: flex;
        justify-content: center;
    }

    .clearModal {
        background: #909090;
        border-radius: 50%;
        height: 43px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;
    }

    .clearModal > img {
        width: 20px;
        height: 20px;
    }

    .modalHeaderBasket {
        height: 122px;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.87) 83.85%, rgba(255, 255, 255, 0) 100%);
        position: sticky;
        padding: 50px 0 30px 22px;
        justify-content: space-between;
        top: 0;
        left: 0;
        z-index: 2;
        margin-bottom: 0;

    }

    .promoHeader {
        padding: 30px 0 0 16px;
        margin-bottom: 30px;
    }

    .circlePromo {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 17px;
        right: 14px;
        position: absolute;
    }

    .circlePromo > img {
        height: 14px;
        width: 14px;
    }

    .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 28px;
        right: 17px;
        position: absolute;
    }

    .emptyCart {
        background: url("../assets/images/emptyCart.svg") -215px -70px no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 30px;
        height: 528px;
    }
}
