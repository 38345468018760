.title {
    width: 100%;
    max-width: 1240px;
    margin: 30px auto 10px;
    padding: 0 20px;
}

.banner {
    width: calc(100% - 90px);
    margin: 20px auto 35px;
    height: fit-content;
    max-width:1200px;
    max-height:272px;
    border-radius: 18px;
    position: relative;
}

.image > img {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    max-height: 272px;
    border-radius: 18px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 768px) {
    .banner {
        width: 100%;
        padding: 0 20px;
        margin: 20px auto 35px;
        height: fit-content;
        max-width:1200px;
        max-height:272px;
        border-radius: 18px;
        position: relative;
    }
}

@media (max-width: 420px) {
    .banner {
        margin: 20px 0 28px;
        max-height:171px;
    }
}
