.banner {
    width: calc(100% - 35px);
    margin: 0 auto;
    max-width: 1240px;
    position: relative;
}

.grid {
    max-width: 281px;
    padding: 0 5px;
}

