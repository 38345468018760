.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 18px 58px 31px;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 62px 35px 38px 40px;
        position: relative;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
    }

    .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5F5F5;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 14px;
        top: 17px;
    }

    .circle > img{
        width: 14px;
        height: 14px;
    }

}