.wrapper {
    width: 281px;
    height: 447px;
    background: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: relative;
    align-self: center;
    overflow: hidden;
    transition-duration: 200ms;
}


.wrapper:active {
    transform: scale(.98);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);

}

.cardImg {
    margin-bottom: 19px;
    position: relative;
}

.imgOut {
    filter: grayscale(100%);
    transition-duration: .3s;
}

.wrapper:hover .imgOut {
    filter: grayscale(0);
}

.mainImg {
    overflow: hidden;
}

.mainImg > img {
    width: 100%;
    object-fit: cover;
}

.mainImg > img::before {
    float: left;
    padding-top: 100%;
    content: '';
}
.mainImg > img::after {
    display: block;
    content: '';
    clear: both;
}

.counter {
    width: 187px;
    height: 59px;
    border-radius: 17px;
    visibility: hidden;
    backdrop-filter: blur(10px);
    position: absolute;
    top: 137px;
    right: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

}

.quantityIsLimited {
    background: #fff;
    border-radius: 4px;
    width: 90%;
    height: 25px;
    margin: 6px auto 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'HelveticaNeueCyr-Bold',sans-serif;
    text-align: center;
    position: absolute;
    top: 196px;
    left: 5%;
    display: grid;
    place-items: center;
}

.visible {
    visibility: visible;
    opacity: 1;
    background-color: rgba(255,255,255,0.5);
}

.minusDarkBlue, .plusDarkBlue, .garbage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
    padding: 0 13px;
}

.garbage {
    padding: 0 16px;
}


.cardName, .cardMeasure, .cardPrice, .cardSale {
    margin-left: 23px;
}

.cardName {
    margin-bottom: 10px;
    width: calc(100% - 40px);
    /*height: 52px;*/
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.cardSale {
    margin-bottom: 10px;
    position: relative;
    text-decoration: none;

}

.cardSale:before {
    content: '';
    border-bottom: 2px solid #FF5252;
    position: absolute;
    border-radius: 2px;
    top: 2px;
    width: 110%;
    height: 50%;
    left: -3px;
    z-index: 1;
}

.sale {
    margin-bottom: 11px;
}

.noSale {
    margin-bottom: 35px;
}

.saleWrapper {
    display: flex;

}

.percent {
    margin-left: 14px;
    background: #00A469;
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.shoppingCart {
    position: absolute;
    bottom: 18px;
    right: 18px;
}

.mailMe {
    background: #c4c4c4;
    width: 53px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition-duration: .3s;
}

.notifyMe {
    background: #00A469;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    height: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-duration: .3s;

}

.wrapper:hover .notifyMe {
    opacity: 1;
    height: 50px;
}

.wrapper:hover .mailMe {
    background: #00A469;
}

/* 1200px, 992px, 768px, 576px */


@media (max-width: 992px) {
    .wrapper {
        width: 164px;
        height: 291px;
        background: #fff;
        box-shadow: 0 0 0 0;
        border-radius: 0;
        position: relative;
        align-self: center;
        margin-bottom: 15px;

    }

    .cardImg {
        margin-bottom: 11px;
        position: relative;
    }

    .mainImg > img {
        height: 163px;
        width: 163px;
        overflow: hidden;
        border-radius: 6px;
    }

    .counter {
        width: 153px;
        height: 49px;
        border-radius: 17px;
        position: absolute;
        top: 53px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;

    }

    .quantityIsLimited {
        line-height: 14px;
        top: 102px;
        left: 5%;
        height: 42px;
    }

    .minusDarkBlue, .plusDarkBlue, .garbage {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 49px;
        padding: 0 10px;
    }


    .cardName, .cardMeasure, .cardPrice, .cardSale {
        margin-left: 0;
    }

    .cardName {
        margin-bottom: 0;
        font-size: 12px;
        max-width: 158px;
    }

    .cardSale {
        margin-bottom: 0px;
        position: relative;
        text-decoration: none;

    }

    .cardSale:before {
        content: '';
        border-bottom: 2px solid #FF5252;
        position: absolute;
        border-radius: 2px;
        top: 0px;
        width: 110%;
        height: 50%;
        left: -3px;
        z-index: 1;

    }

    .sale {
        margin-bottom: 11px;
    }

    .noSale {
        margin-bottom: 35px;
    }

    .saleWrapper {
        display: flex;

    }

    .percent {
        display: none;
    }

    .shoppingCart {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media (max-width: 374px) {
    .wrapper {
        width: 145px;
        height: 270px;
        background: #fff;
        box-shadow: 0 0 0 0;
        border-radius: 0;
        position: relative;
        align-self: center;

    }

    .cardImg {
        margin-bottom: 11px;
        position: relative;
    }

    .mainImg > img {
        height: 144px;
        width: 144px;
        overflow: hidden;
        border-radius: 6px;
    }

    .counter {
        width: 133px;
        height: 49px;
        top: 53px;
        right: 5px;
    }


    .minusDarkBlue, .plusDarkBlue, .garbage {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 49px;
        padding: 0 10px;
    }



    .cardName, .cardMeasure, .cardPrice, .cardSale {
        margin-left: 0;
    }

    .cardName {
        margin-bottom: 0;
        max-width: 158px;
        font-size: 12px;
    }

    .cardSale {
        margin-bottom: 0px;
        position: relative;
        text-decoration: none;

    }

    .cardSale:before {
        content: '';
        border-bottom: 2px solid #FF5252;
        position: absolute;
        border-radius: 2px;
        top: 0px;
        width: 110%;
        height: 50%;
        left: -3px;
        z-index: 1;

    }

    .sale {
        margin-bottom: 11px;
    }

    .noSale {
        margin-bottom: 35px;
    }

    .saleWrapper {
        display: flex;

    }

    .percent {
        display: none;
    }

    .shoppingCart {
        position: absolute;

        bottom: 0px;
        right: 0px;
    }

    .shoppingCart > img {
        height: 40px;
        width: 40px;
    }
}




