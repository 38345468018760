.wrapper {
    background: #213140;
    padding-top: 32px;
    padding-bottom: 25px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.container > * {
    margin-right: 25px;
}

.container > *:last-child {
    margin-right: 0;
}

.logo {
    display: flex;
    flex-direction: column;

}

.logoFooterMobile{
    display: none;
}

.company {
    margin-bottom: 24px;
}

.companyHeader, .infoHeader, .personalHeader, .orderHeader {
    margin-bottom: 8px;
}

.personalCards, .orderSupport, .orderWhatsapp{
    display: flex;
    align-items: flex-start;
}



.social {
    display: flex;
}

.social a {
    margin-right: 8px;
}

.contacts {
    display: flex;
    position: relative;
    height: fit-content;
}

.helpBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 300px;
    height: 45px;
    background: #00A469;
    border-radius: 40px;
    cursor: pointer;
    z-index: 102;
}

.popUp {
    width: 300px;
    height: 235px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-top: 40px;
    gap: 23px;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 101;
    border-radius: 24px;
    background: #fff;
    -webkit-box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
    box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
}

.mailBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #1850df;
    border-radius: 40px;
    cursor: pointer;
}

.waBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #65da4b;
    border-radius: 40px;
    cursor: pointer;
}

.mobileWrapper {
    background: #213140;
    padding: 34px 20px 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.line {
    background: #909090;
    height: 1px;
    width: 100%;
    margin-top: 47px;
    margin-bottom: 24px;
}

/* 1200px, 992px, 768px, 576px */

@media (max-width: 1200px) {
    .container {
        max-width: 992px;
        padding: 0 15px;
    }
    .logo {
        display: none;
    }
    .social {
        display: none;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 320px;
        display: grid;
        grid-template-columns: 320px;
        position: relative;
    }

    .order {
        grid-row-start: 1;
        grid-row-end: 2;
        width: 100%;
        padding:0 15px 24px;
        margin:0 -15px;
        border-bottom: #909090 1px solid;
        margin-bottom: 24px;
    }

    .personalAccount {
        display: none;
    }

    .social {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    .logoFooterMobile{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 167px;
        right: 0;
    }

    .logoFooterMobile img {
        height: 54px;

    }

}
