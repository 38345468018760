div {
    box-sizing: border-box;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

html {
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: auto !important;
    -webkit-tap-highlight-color:transparent;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
}

input::placeholder, input {
    text-decoration: none !important;
}

@keyframes pulse {
    0% {
        transform: scale(0.90);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}

body {
    user-select: none;
    background: url("./assets/images/backgroundMain.png") repeat fixed;
}
@media (max-width: 1200px) {
    body {
        background: #fff;
    }
}

::-webkit-scrollbar {
    width: 6px;
    display: block;
    height: 4px;
    position: relative;
    right: 15px;
    top:0;
    margin-right: 12px;

}


/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    max-height: 80%;
    height: 100px;
    margin: 20px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #213140;
    width: 6px;
    border-radius: 4px;
}

.carousel-container a {
    background-color: transparent;
    opacity: 0;
}

.carousel-container:hover a {
    background-color: transparent;
    opacity: 1;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

:focus::-webkit-input-placeholder {
    color: transparent
}

:focus::-moz-placeholder {
    color: transparent
}

:focus:-moz-placeholder {
    color: transparent
}

:focus:-ms-input-placeholder {
    color: transparent
}
