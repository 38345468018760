.ordersWrapper {
    margin: 40px 0 0 50px;
    padding-bottom: 150px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.orderInfo {
    display: flex;
    flex-direction: column;
}

.orderStatus {
    display: flex;
    align-items: flex-start;
}

.fullOrder {
    width: 100%;
    max-width: 806px;
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

@media (max-width: 650px) {
    .ordersWrapper {
        margin: 0;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }

    .orderInfo {
        display: flex;
        flex-direction: column;
    }

    .orderStatus {
        display: flex;
        align-items: flex-start;
    }

    .fullOrder {
        /*max-width: 335px;*/
        margin: 0 20px 15px;
        width: calc(100% - 40px);
        border-bottom: 1px solid #EDEDED;
        padding-bottom: 15px;
    }
}
