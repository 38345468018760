.overlay {
    transition: opacity ease 300ms;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.33);
    position: fixed;
    width: 100vw;
    height: 100% !important;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    overflow: auto;
}

.overlayInvisible {
    opacity: 0;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    /*width: 100vw;*/
    background-color: #ffffff;
    border-radius: 20px;
    transition-duration: 300ms;
    /*height: 100%;*/
    position: relative;
    /*height: -moz-available !important;          !* WebKit-based browsers will ignore this. *!*/
    /*height: -webkit-fill-available !important;  !* Mozilla-based browsers will ignore this. *!*/
    /*height: fill-available !important;*/
}

.containerInvisible {
    margin-top: -100px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    top:45px;
    right: 45px;
    position: absolute;
}

@media (max-width: 768px) {
    .container {
        overflow-y: auto;
        overflow-x: hidden;
        /*width: 100vw;*/
        background-color: #ffffff;
        transition-duration: 300ms;
    }

    .modalHeader {
        display: flex;
        padding: 18px 18px 0;
        justify-content: space-between;
        position: relative;

    }
}

@media (min-width: 992px) {
    .overlay {
        justify-content: center;
    }
}
