.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    border-radius: 50%;
    width: 26px;
    height: 26px;
}

.circle > img {
    width: 14px;
    height: 14px;
}

.grid {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-bottom: 12px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 100;
}

.touchableArea {
    padding: 0 20px;
}

.headerTitle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
