.wrapper {
    max-width: 1240px;
    margin: 60px auto;
    padding: 0 20px;
}

.container {
    max-width: 891px;
}

.li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
}

.li:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #213140;
    border-radius: 50%;
    position: absolute;
    top:5px;
    left:5px;
}

@media (max-width: 768px) {
    .container {
        margin: 0 auto;
    }
}