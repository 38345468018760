.input {
    outline: none;
    border: none;
    box-sizing: border-box;
    transition-duration: .3s;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 5px;
    border-bottom: 1px solid #EDEDED;
    font-size: 20px;
    font-weight: 550;
    line-height: 102%;
    /*max-width: 334px;*/
    color: #213140;
}

.input::placeholder {
    font-family: 'HelveticaNeueCyr-Roman',sans-serif;
    font-size: 20px;
}

.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    border-bottom: solid 2px #888;
    margin-top: -2px;
    box-sizing: border-box;
    transition-duration: .3s;
    background-color: transparent;
    padding-left: 0 !important;
}

.input:focus::placeholder {
    color: transparent;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.input:disabled {
    color: black
}


.title {
    transition-duration: .3s;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
    margin-bottom: 7px;
    display: flex;
}

.requiredStar {
    color: #FF5252;
    margin-left: 4px;
    transition-duration: .3s;
}
