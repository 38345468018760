.wrapper {
    padding: 33px 0 7px 51px;
    border-bottom: 4px solid #EDEDED;
    position: sticky;
    background: #fff;
    z-index: 100;
    top: 0
}

.user {
    display: flex;
    margin-bottom: 55px;
}

.circle {
    background: #F5F5F5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    top:37px;
    right:50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.budge {
    position: absolute;
    bottom: -4px;
    right: -11px;
    background: #EDEDED;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.userInfo {
    display: flex;
    flex-direction: column;
}

.nav {
    display: flex;
}

.navItem {
    border-bottom: solid 4px transparent;
    padding-bottom: 6px;
    margin-bottom: -11px;
    cursor: pointer;
    transition-duration: .3s;
    margin-right: 65px;
}

.navItem:last-child {
    margin-right: 0;
}

.navItemActive {
    border-bottom: solid 4px #00A469;
}


@media (max-width: 650px) {
    .wrapper {
        padding: 40px 20px 0 20px;
        border-bottom: none;
    }

    .user {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .circle {
        background: #F5F5F5;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        position: absolute;
        top:41px;
        right:20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle > img {
        width: 14px;
        height: 14px;
    }

    .budge {
        position: absolute;
        top: 33px;
        left: 40px;
        background: #EDEDED;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
    }

    .nav {
        display: flex;
        flex-direction: column;
    }


}
