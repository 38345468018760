.selectInput {
    background: #FCFCFE;
    border: 0.5px solid #909090;
    border-radius: 3px;
    height: 43px;
    min-width: 290px;
    padding: 0 22px;
    appearance: none;
    position: relative;
    cursor: pointer;
}

.arrow {
    position: absolute;
    top: 16px;
    right: 16px;
    pointer-events: none;
}
