.overlay {
    transition: opacity ease 300ms;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.33);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalTitle {

}

.overlayInvisible {
    opacity: 0;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    border-radius: 20px;
    transition-duration: 300ms;
    position: relative;
}

.containerInvisible {
    margin-top: -100px;
}

.modalHeader {
    display: flex;
    padding: 50px 50px 00px;
    justify-content: space-between;
    position: relative;
}

.delivery {
    background-color: #213140;
    border-radius: 4px;
    color: #fff;
    padding: 7px 25px;
    width: 100%;
    max-width: 385px;
    text-align: center;

}

.modalHeaderBasket {
    height: 122px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.87) 83.85%, rgba(255, 255, 255, 0) 100%);
    position: sticky;
    padding: 45px 50px 0;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: 2;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    top:45px;
    right: 45px;
    position: absolute;
}

@media (max-width: 420px) {
    .circle {
        top: 16px;
        right: 16px;
    }
}

@media (max-width: 375px) {
    .container {
        max-width: 303px;
        max-height: 511px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        border-radius: 20px;
        transition-duration: 300ms;
    }



    .content {
        padding: 18px;
    }

    .modalHeader {
        display: flex;
        padding: 18px 18px 0;
        justify-content: space-between;
        position: relative;

    }
}
