.wrapper {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 15px;
    position: relative;
}

@media (max-width: 1439px) {
    .wrapper {
        max-width: 895px;
    }
}

@media (max-width: 1023px) {
    .wrapper {
        margin: 0 auto;
        padding: 0 15px 50px;
    }
}

.header {
    padding: 20px 0;
    place-items: center;
    background: #fff;
    margin: 0 0 10px;
}

.goBack {
    position: absolute;
    left: 25px;
    top: 25px;
}

.title {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    color: #383838;
    margin-bottom: 10px;
    font-family: 'DINPro-Bold', sans-serif;
}

.greenTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #43b02a;
    font-family: 'DINPro-Regular', sans-serif;
    margin-bottom: 20px;
}

.earnedWrapper {
    max-width: 375px;
    width: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*  position: sticky;
      top: 100px;
      left: 0;
      z-index: 20;*/
}

@media (max-width: 1023px) {
    .earnedWrapper {
        top: 56px;
    }
}

.pointsEarned {
    background: #fff;
    border-radius: 4px;
    display: grid;
    place-items: center;
    height: 32px;
    width: 61px;
}

.qWrapper {
    width: 345px;
    margin-bottom: 30px;
}

.qTitle {
    font-size: 14px;
    font-family: 'DINPro-Medium', sans-serif;
    color: #818181;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.qTitle > span {
    font-family: 'DINPro-Bold', sans-serif;
    color: #383838;
    font-weight: 700;
}

.qFlex {
    display: flex;
    align-items: flex-start;
    gap: 25px;
}

.qPoints {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    width: 39px;
}

.emptyCircle {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid #43b02a;
}

.amountGray {
    color: #d1d1d1;
    font-weight: 900;
    font-family: 'DINPro-Black', sans-serif;
    font-size: 16px;
    margin-right: 3px;
}

.amountGreen {
    color: #43b02a;
    font-weight: 900;
    font-family: 'DINPro-Black', sans-serif;
    font-size: 16px;
    margin-right: 3px;
}

.nameInput {
    height: 55px;
    flex: 1;
}

.nameInput input, .nameInput select {
    height: 55px;
    padding: 0 14px;
    background: #fff;
    border-radius: 12px;
    width: 100%;

    color: #2f2f2f;
    font-family: 'DINPro-Bold', sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.nameInput input::placeholder {
    font-family: 'DINPro-Regular', sans-serif;
    font-weight: 400;
}

.birthdayInput input, .birthdayInput input::placeholder {
    text-align: center;
}

.emptyRadioCircle {
    height: 19px;
    width: 19px;
    border-radius: 50%;
    border: 1px solid #383838;
}

.fullRadioCircle {
    height: 19px;
    width: 19px;
    padding: 2px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #383838;
    outline: 1px solid #383838;
}

.sexInput {
    padding: 15px 40px 15px 20px;
    height: 65px;
    background: #fff;
    border-radius: 12px;
    width: 223px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.sexInput > div {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.sexInput > div > img {
    height: 28px;
}

.pets {
    background: #fff;
    padding: 10px 22px;
    border-radius: 12px;
    flex: 1;
}

.pets img, .how img {
    height: 28px;
}

.petRow {
    display: flex;
    align-items: center;
    height: 35px;
    cursor: pointer;
}

.petRow img:first-child {
    margin-right: 11px;
    height: 19px;
}

.petRow span {
    margin-right: 5px;
}

.children {
    background: #fff;
    padding: 20px 22px 0;
    border-radius: 12px;
    flex: 1;
}

.childrenRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.how {
    background: #fff;
    padding: 15px 0 10px 20px;
    border-radius: 12px;
    flex: 1;
}

.how img {
    margin-left: 5px;
}

.how img:first-child {
    margin-left: 0px;
}

.qColumn {
    background: #fff;
    padding: 80px 18px 0;
    position: relative;
    border-radius: 12px;
}

.storesTitle {
    position: absolute;
    top: 20px;
    right: 25px;
    text-align: center;
}

.storeRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 11px 0;
    border-bottom: 1px #f5f5f5 solid;
    margin-bottom: 13px;
}

.storeRow:last-child {
    border-bottom: none;
    padding-bottom: 24px;
}

.multiCheckboxes {
    width: 101px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.qTitleSpecial {
    display: flex;
    justify-content: space-between;
}

.suggestionsWrapper {
    position: absolute;
    max-width: 300px;
    overflow-y: scroll;
    top: 60px;
    left: 0;
    width: 290px;
    background: #fff;
    border-radius: 6px;
    z-index: 5;
}

.suggest {
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    font-family: "DINPro-Regular", sans-serif;
    line-height: 15px;
    padding: 10px;
}

.suggest:hover {
    background: #f7f7f7;
}

.popularTitle {
    position: absolute;
    top: 20px;
    right: 25px;
    width: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agree {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 35px;
}

.submitBtn {
    height: 55px;
    display: grid;
    place-items: center;
    background: #43b02a;
    border-radius: 24px;
    max-width: 345px;
    cursor: pointer;
}