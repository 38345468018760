.wrapper {

    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    height: fit-content;
}

.background {
    background: #FCFCFE;
}

.description  {
    font-size: 14px;
    color: #213140;
    font-weight: 500 !important;
    font-family: 'HelveticaNeueCyr-Roman', sans-serif;
    user-select: text;
}
.description > p > strong {
    font-weight: bold !important;
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
}

.product {
    padding-bottom: 50px;
    display: flex;

}

.productImg > img {
    width: 100%;
    max-width: 485px;
    border-radius: 28px;
}

.productImg > img {
    width: 100%;
    object-fit: cover;
}

.productImg > img::before {
    float: left;
    padding-top: 100%;
    content: '';
}
.productImg > img::after {
    display: block;
    content: '';
    clear: both;
}

.productImg {
    margin-right: 50px;
    position: relative;
}

.freeze {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 44px;
    z-index: 5;
}

.mainInfo {
    width: 100%;
    max-width: 460px;
}

.productName {
    max-width: 460px;
    margin-bottom: 10px;
    user-select: text;
}

.productWeight {
    margin-bottom: 35px;
}

.priceBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-end;
    width: 100%;
    max-width: 460px;
}

.saleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

}

.oldPrice {
    position: relative;
    margin-right: 12px;

}

.oldPrice:before {
    content: '';
    border-bottom: 1px solid #BDBDBD;
    position: absolute;
    border-radius: 1px;
    top: 0;
    width: 110%;
    height: 50%;
    left: 0;
    z-index: 1;
}

.sale {
    background: #00A469;
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.priceWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    max-width: 190px;
    margin-right: 40px;
}

.newPrice {
    margin-right: 8px;
    display: flex;
}

.description, .composition, .proteins, .energy {
    margin-bottom: 12px;
}

.counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 50px;
    border-radius: 25px;
    background: #EDEDED;
}

.minus, .plus, .garbage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    padding: 0 13px;
    background: #00A469;
    border-radius: 50%;
}

.banner {
    padding-bottom: 40px;
}

.recommended {
    display: grid;
    grid-gap: 36px 10px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 100vw;
    grid-template-columns: repeat(auto-fit, minmax(163px, 1fr));
}

.quantityIsLimited {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #213140;
    position: absolute;
    top: -50%;
    left: 5px;
    z-index: 5;
    width: fit-content;
}

.badgesWrapper {
    position: absolute;
    top: -13px;
    left: 1px;
    z-index: 5;
    height: fit-content;
}

.badge img {
    height: 40px;
    margin-bottom: 7px;
}

@media (max-width: 890px) {
    .badge img {
        height: 34px;
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        max-width: 768px;
        margin: 0 auto;
    }

    .background {
        background: #FCFCFE;
    }

    .product {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
    }

    .productImg > img {
        width: 100%;
        max-width: 768px;
        border-radius: 16px;
    }

    .productImg {
        margin-right: 0;
        margin-bottom: 25px;
    }

    .mainInfo {
        max-width: 768px;
    }

    .productName {
        max-width: 768px;
        margin-bottom: 8px;
    }

    .productWeight {
        margin-bottom: 18px;
    }

    .priceBlock {
        display: flex;
        justify-content: space-between;
        max-width: 768px;
        margin-bottom: 40px;
        align-items: flex-end;
    }

    .saleWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

    }

    .oldPrice {
        position: relative;
        margin-right: 12px;

    }

    .oldPrice:before {
        content: '';
        border-bottom: 1px solid #BDBDBD;
        position: absolute;
        border-radius: 1px;
        top: 0;
        width: 110%;
        height: 50%;
        left: 0;
        z-index: 1;
    }

    .sale {
        background: #00A469;
        width: 50px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }

    .priceWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-right: 0px;
    }

    .newPrice {
        display: flex;
        margin-right: 4px;
    }

    .weight {
    }

    .description, .composition, .proteins, .energy {
        margin-bottom: 12px;
    }

    .counter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 170px;
        height: 50px;
        border-radius: 25px;
        background: #EDEDED;
    }

    .minus, .plus, .garbage {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        padding: 0 13px;
        background: #00A469;
        border-radius: 50%;
    }

    .banner {
        padding: 0 20px 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }

    .quantityIsLimited {
        line-height: 14px;
        top: -65%;
        left: 5px;
        z-index: 5;
        text-align: center;
    }

    .recommended {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));

        grid-gap: 36px 10px;
        margin-bottom: 40px;
    }
}
