.Landing_wrapper__1dp4W {
	margin: 0 auto;
	max-width: 2014px;
}

.Landing_firstLine__3Y70W {
	padding: 50px 250px;
	background: #fff;
}

.Landing_firstLine__3Y70W .Landing_wrapper__1dp4W {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

@media (max-width:1200px) {
	.Landing_firstLine__3Y70W .Landing_wrapper__1dp4W {
		flex-wrap: wrap;
	}
}

.Landing_firstLine__3Y70W .Landing_holder__13iQw {
	margin-top: 160px;
}

@media (max-width:1200px) {
	.Landing_firstLine__3Y70W .Landing_holder__13iQw {
		margin: 50px auto 0;
	}
}

.Landing_firstLine__3Y70W .Landing_logo__r8sdC {
	height: 88px;
	margin-bottom: 58px;
}

@media (max-width:1200px) {
	.Landing_firstLine__3Y70W .Landing_logo__r8sdC {
		height: 50px;
	}
}

@media (max-width:568px) {
	.Landing_firstLine__3Y70W .Landing_logo__r8sdC {
		height: 40px;
	}
}

@media (max-width:1200px) {
	.Landing_firstLine__3Y70W .Landing_textHolder__3k0SG {
		width: 100%;
	}
}

.Landing_firstLine__3Y70W .Landing_textHolder__3k0SG p {
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 44px;
	line-height: 48px;
	color: #131313;
	margin-bottom: 30px;
}

@media (max-width:1800px) {
	.Landing_firstLine__3Y70W .Landing_textHolder__3k0SG p {
		font-size: 38px;
		line-height: 40px;
	}
}

@media (max-width:1600px) {
	.Landing_firstLine__3Y70W .Landing_textHolder__3k0SG p {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_firstLine__3Y70W .Landing_textHolder__3k0SG p {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_firstLine__3Y70W .Landing_iphone__2K7u0 {
	margin-top: -50px;
}

@media (max-width:1800px) {
	.Landing_firstLine__3Y70W .Landing_iphone__2K7u0 {
		margin-left: 30px;
	}
}

@media (max-width:1200px) {
	.Landing_firstLine__3Y70W .Landing_iphone__2K7u0 {
		margin: 0 auto -660px;
	}
}

@media (max-width:700px) {
	.Landing_firstLine__3Y70W .Landing_iphone__2K7u0 {
		width: 300px;
		margin-bottom: -200px;
	}
}

@media (max-width:1600px) {
	.Landing_firstLine__3Y70W {
		padding: 50px;
	}
}

@media (max-width:568px) {
	.Landing_firstLine__3Y70W {
		padding: 25px;
	}
}

.Landing_secondLine__3aJB9 {
	background: #048d5c;
	padding: 160px 400px;
	margin-top: -270px;
}

.Landing_secondLine__3aJB9 .Landing_wrapper__1dp4W {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width:1200px) {
	.Landing_secondLine__3aJB9 .Landing_wrapper__1dp4W {
		flex-wrap: wrap;
	}
}

.Landing_secondLine__3aJB9 .Landing_qr__3n-ZQ {
	margin-right: 54px;
}

@media (max-width:1200px) {
	.Landing_secondLine__3aJB9 .Landing_qr__3n-ZQ {
		margin: 0 auto 30px;
	}
}

@media (max-width:1200px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG {
		margin: 0 auto;
		width: 100%;
		text-align: center;
	}
}

.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG p {
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 44px;
	line-height: 48px;
	color: #fff;
	margin-bottom: 30px;
}

@media (max-width:1800px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG p {
		font-size: 38px;
		line-height: 40px;
	}
}

@media (max-width:1600px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG p {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG p {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG a {
	width: 513px;
	height: 78px;
	background: #fff;
	border-radius: 68px;
	display: inline-block;
	font-family: "HelveticaNeueCyr-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 78px;
	text-align: center;
	color: #000;
}

@media (max-width:1600px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG a {
		font-size: 30px;
		width: auto;
		display: block;
	}
}

@media (max-width:568px) {
	.Landing_secondLine__3aJB9 .Landing_textHolder__3k0SG a {
		font-size: 24px;
		height: 50px;
		line-height: 50px;
	}
}

@media (max-width:1600px) {
	.Landing_secondLine__3aJB9 {
		padding: 800px 50px 125px;
	}
}

@media (max-width:700px) {
	.Landing_secondLine__3aJB9 {
		padding-top: 440px;
	}
}

@media (max-width:568px) {
	.Landing_secondLine__3aJB9 {
		padding: 440px 25px 25px;
	}
}

.Landing_thirdLine__F-Ke0 {
	padding: 50px 250px;
	background: #fff;
}

.Landing_thirdLine__F-Ke0 .Landing_wrapper__1dp4W {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

@media (max-width:1200px) {
	.Landing_thirdLine__F-Ke0 .Landing_wrapper__1dp4W {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
}

.Landing_thirdLine__F-Ke0 .Landing_holder__13iQw {
	margin-top: 160px;
}

@media (max-width:1200px) {
	.Landing_thirdLine__F-Ke0 .Landing_holder__13iQw {
		margin: 0 auto;
	}
}

.Landing_thirdLine__F-Ke0 .Landing_textHolder__3k0SG p {
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 44px;
	line-height: 48px;
	color: #131313;
	margin-bottom: 30px;
}

@media (max-width:1800px) {
	.Landing_thirdLine__F-Ke0 .Landing_textHolder__3k0SG p {
		font-size: 38px;
		line-height: 40px;
	}
}

@media (max-width:1600px) {
	.Landing_thirdLine__F-Ke0 .Landing_textHolder__3k0SG p {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_thirdLine__F-Ke0 .Landing_textHolder__3k0SG p {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_thirdLine__F-Ke0 .Landing_iphone__2K7u0 {
	margin-top: -130px;
}

@media (max-width:1800px) {
	.Landing_thirdLine__F-Ke0 .Landing_iphone__2K7u0 {
		margin-right: 30px;
	}
}

@media (max-width:1200px) {
	.Landing_thirdLine__F-Ke0 .Landing_iphone__2K7u0 {
		margin: 0 auto -600px;
	}
}

@media (max-width:700px) {
	.Landing_thirdLine__F-Ke0 .Landing_iphone__2K7u0 {
		width: 247px;
		margin-top: 30px;
		margin-bottom: -200px;
	}
}

@media (max-width:1600px) {
	.Landing_thirdLine__F-Ke0 {
		padding: 50px;
	}
}

@media (max-width:568px) {
	.Landing_thirdLine__F-Ke0 {
		padding: 25px;
	}
}

.Landing_fourthLine__3sqxT {
	background: #048d5c;
	padding: 160px 250px;
	margin-top: -130px;
}

.Landing_fourthLine__3sqxT .Landing_wrapper__1dp4W {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width:1200px) {
	.Landing_fourthLine__3sqxT .Landing_wrapper__1dp4W {
		flex-wrap: wrap;
	}
}

.Landing_fourthLine__3sqxT .Landing_qr__3n-ZQ {
	margin-right: 54px;
}

@media (max-width:1200px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG {
		width: 100%;
		margin: 0 auto 30px;
	}
}

.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG p {
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 42px;
	color: #fff;
	margin-bottom: 30px;
}

.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG p span {
	font-weight: 700;
}

@media (max-width:1800px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG p {
		font-size: 34px;
		line-height: 36px;
	}
}

@media (max-width:1600px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG p {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG p {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D {
	display: flex;
	align-items: center;
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 70px;
	color: #fff;
	margin-bottom: 15px;
}

.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D:last-of-type {
	margin-bottom: 0;
}

.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D span {
	background: #fff;
	width: 59px;
	height: 59px;
	color: #048d5c;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 23px;
	font-weight: 900;
	font-size: 37px;
}

@media (max-width:700px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D span {
		width: 36px;
		height: 36px;
		font-size: 20px;
		line-height: 1px;
	}
}

@media (max-width:1800px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D {
		font-size: 34px;
		line-height: 36px;
	}
}

@media (max-width:1600px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_fourthLine__3sqxT .Landing_textHolder__3k0SG .Landing_numeric__2Qw4D {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h p {
	font-family: "HelveticaNeueCyr-Roman";
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 42px;
	color: #fff;
	margin-bottom: 30px;
}

@media (max-width:1800px) {
	.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h p {
		font-size: 34px;
		line-height: 36px;
	}
}

@media (max-width:1600px) {
	.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h p {
		font-size: 30px;
		line-height: 32px;
	}
}

@media (max-width:568px) {
	.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h p {
		font-size: 24px;
		line-height: 26px;
	}
}

.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h a {
	width: 100%;
	height: 91px;
	background: #fff;
	border-radius: 76px;
	display: inline-block;
	font-family: "HelveticaNeueCyr-Medium";
	font-style: normal;
	font-weight: 500;
	font-size: 44px;
	line-height: 91px;
	text-align: center;
	color: #000;
	display: block;
	margin: 76px auto 0;
	cursor: pointer;
}

@media (max-width:1600px) {
	.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h a {
		font-size: 30px;
		width: auto;
	}
}

@media (max-width:568px) {
	.Landing_fourthLine__3sqxT .Landing_rightBlock__2Pv0h a {
		font-size: 20px;
		height: 50px;
		line-height: 50px;
	}
}

@media (max-width:1600px) {
	.Landing_fourthLine__3sqxT {
		padding: 100px 50px;
	}
}

@media (max-width:1200px) {
	.Landing_fourthLine__3sqxT {
		padding-top: 800px;
	}
}

@media (max-width:700px) {
	.Landing_fourthLine__3sqxT {
		padding-top: 380px;
	}
}

@media (max-width:568px) {
	.Landing_fourthLine__3sqxT {
		padding: 380px 25px 25px;
	}
}

.Landing_certWrapper__3FJj_ {
	position: fixed;
	z-index: 99999999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Landing_certWrapper__3FJj_ .Landing_certOverlay__10tTX {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	background: rgba(0,0,0,.5607843137254902);
}

.Landing_certWrapper__3FJj_ .Landing_certHolder__1Qxff {
	width: 600px;
	max-width: 100vw;
	height: 100vh;
	overflow-y: auto;
	padding: 0 10px;
	position: relative;
	z-index: 5;
}

.Landing_certWrapper__3FJj_ .Landing_certHolder__1Qxff img {
	margin: 5px 0;
	max-width: 100%;
}

.Landing_certWrapper__3FJj_ .Landing_certHolder__1Qxff .Landing_closer__1OgWY {
	position: fixed;
	right: 20px;
	top: 20px;
}