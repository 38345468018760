.wrapper {
    padding: 60px 20px 20px 20px;
    background-color: #FCFCFE;
}

.user {
    display: flex;
    /*gap: 25px;*/
    align-items: center;
    margin-bottom: 45px;
}

.user > * {
    margin-right: 25px;
}

.user > *:last-child {
    margin-right: 0;
}

.category {
    height: 89px;
    background: #fff;
    width: 89px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 3px 0 6px;
    overflow: hidden;
    margin: 4.5px;
    position: relative;
}

.categoryIcon > img {
    width: 54px;
    height: 54px;
    margin-left: -4px;
    margin-top: -8px;
    object-fit: contain;
}

.categoryName {
    overflow: hidden;
    width: 84px;
    word-break: keep-all;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 5px;
    left: 3px;
    z-index: 10000;
}

.categoryName a{
    color: #213140;
}

.categoryName > span {
    max-width: 80px;
}

.categories {
    display: flex;
    margin: -4.5px;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

.info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.info span, .info a {
    margin-bottom: 25px;
}

.contacts {
    display: flex;
    position: relative;
    height: fit-content;

}

.helpBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 300px;
    height: 45px;
    background: #00A469;
    border-radius: 40px;
    cursor: pointer;
    z-index: 10200;
}

.popUp {
    width: 285px;
    height: 235px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-top: 40px;
    gap: 23px;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 10100;

    border-radius: 24px;
    background: #fff;
    -webkit-box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
    box-shadow: 0 2px 11px -1px rgba(0,0,0,0.37);
}

.mailBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #1850df;
    border-radius: 40px;
    cursor: pointer;
}

.waBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 258px;
    height: 45px;
    background: #65da4b;
    border-radius: 40px;
    cursor: pointer;
}
