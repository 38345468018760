.wrapper {
    width: 100%;
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;
    margin: 40px auto 30px;
    padding:  20px;
}

.search {
    max-width: 1240px;
    width: 100%;
    margin: 10px auto 0;
    padding: 0 20px;
}

.suggestions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
.suggestionItem {
    padding: 13px;
    border-radius: 30px;
    margin-right: 10px;
    margin-bottom: 8px;
    background-color: white;
}

@media (max-width: 992px) {
    .wrapper {
        margin: 30px auto 0;
        max-width: 992px;
        grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
        grid-gap: 8px;
        padding:  20px;
    }

    .suggestionItem {
        background-color: #fafafa;
    }
}

@media (max-width: 374px) {
    .wrapper {
        padding: 20px;
        max-width: 374px;
        grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
        grid-gap: 5px;
        justify-content: center;
    }
}


