/*.wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}*/
.container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20px;
    padding: 0;
}

.nextSectionContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 48px;
}

.nextSectionButton {
    background-color: #f0f0f0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    border-radius: 8px;
}
.nextSectionButton > span {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
}

.subcatSelector {
    display: flex;
    overflow: hidden;
    flex-basis: 100%;
    flex-wrap: wrap;
    width: 100%;
    /*gap: 10px;*/
    margin-bottom: 24px;
    margin-top: 12px;
    padding: 10px 0;
}
.subcatSelector > * {
    margin-right: 10px;
}

.subcatSelector > *:last-child {
    margin-right: 0;
}

.subCatText {
    white-space: nowrap;
}

.subCatItem {
    width: max-content;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.subCatActive {
    background-color: #213140;
    color: white;
}

.wrapper {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;

}

/* 1200px, 992px, 768px, 576px */


@media (max-width: 1200px) {
    .wrapper {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}
@media (max-width: 992px) {
    .container{
        width: 100%;
        max-width: 992px;
        margin: 20px auto;
        /*padding: 0 20px*/
    }

    .nextSectionContainer {
        justify-content: flex-end;
    }

    .nextSectionButton {
        width: 230px;
    }

    .wrapper {
        width: 100%;
        margin: 0 auto;
        max-width: 992px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        padding: 0;
        grid-gap: 20px 12px;
    }
}

@media (max-width: 374px) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
        max-width: 374px;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        padding: 0 0px;
        grid-gap: 5px;
    }
}
