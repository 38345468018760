.overlay {
    transition: opacity ease 300ms;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.33);
    position: fixed;
    width: 100vw;
    height: 100% !important;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 10;

}

.overlayInvisible {
    opacity: 0;
}

.container {
    max-width: 325px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    height: 100% !important;
    background-color: #FCFCFE;
    border-radius: 0 20px 20px 0;
    transition-duration: 300ms;
}


.containerInvisible {
    margin-left: -100px;
}

.content {
    position: relative;
}


.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
    background: #F5F5F5;
    border-radius: 50%;
    position: absolute;
    width: 26px;
    height: 26px;
    z-index: 100;
    top:75px;
    left: 330px;
}

.circle img {
    height: 13px;
    width: 13px;
}

@media (max-width: 370px) {
    .container {
        max-width: 285px;
    }
    .circle {
        left: 290px;
    }

}
