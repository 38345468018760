.container {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 50px);
}

.constraints {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    flex: 1
}

.checkOuter {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 40px;
    border-radius: 50%;
    background-color: rgba(45, 204, 112, 0.31);
}

.checkContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info {
    flex: 1;
    display: flex;
    flex-direction: column;
}
