.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px 50px 36px 40px;
    max-width: 375px;
    cursor: pointer;
    width: fit-content;
}

@media (max-width: 498px) {
    .wrapper {
        max-width: 301px;
        padding: 42px 10px 36px 5px;
    }
}
