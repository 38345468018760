.shape{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    width: 100%;
    cursor: pointer;
    border-radius: 40px;
    border: none;
    transition: opacity ease-in-out 0.4s;
}
.shape:active {
    opacity: 0.4;
    transition: opacity ease-in-out 0s;
}
.label{
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 16px;
}

.disabled{
 /*backgroundColor: '#bfbfbf';*/
}